import React, { useState, useContext, Fragment, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

import { makeStyles, Typography, Divider, Drawer, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Box, Container, Button, DialogTitle, DialogActions, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { getNomeUsuario } from '../services/auth';
import logoSistemaNormal from '../assets/img/logoPretoLaranja.svg';
import logoSistemaDark from '../assets/img/logoLaranja.svg';
import { CustomThemeContext } from '../themes/CustomThemeProvider';

import {useDispatch} from 'react-redux';
import * as Actions from '../store/actions';
import ShowDialog from '../components/FuseDialog';
import ShowMessage from '../components/FuseMessage';

import { getSourceArea, logout } from "../services/auth";

import Footer from './footer-admin';
import Sidebar from "./Sidebar";

import Brightness2Icon from '@material-ui/icons/Brightness2';
//import Brightness7Icon from '@material-ui/icons/Brightness7';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 16,
          },
      },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    fixedHeight: {
      height: 240,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      },
    sidebarItem:{
        paddingLeft: 16,
        marginRight: 6,
      },      
    sidebarItemText:{
        overflow: 'hidden',
      },      
  }));

const MenuAdmin = ( itens ) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { currentTheme, setTheme, currentTitle } = useContext(CustomThemeContext);
    const isDark = Boolean(currentTheme === 'dark');

    const localOpen = sessionStorage.getItem('@opengym/appOpen') || 'true'
    const [open, setOpen] = useState(Boolean(localOpen==='true'));

    const [sourceArea, setSourceArea] = useState('');

    useLayoutEffect(() =>{
        getSourceArea().then(resp =>{
            setSourceArea(resp);
            });
    },[]);

    const handleDrawerOpen = () => {
        sessionStorage.setItem('@opengym/appOpen', true);
        setOpen(true);
    };
    const handleDrawerClose = () => {
        sessionStorage.setItem('@opengym/appOpen', false);
        setOpen(false);
    };
    
    // ESCOLHA DO TEMA LIGHT/DARK
    const handleThemeChange = () => {
      if (isDark){
        setTheme('normal');
        return;
      }
      setTheme('dark');
    }

   
    async function Sair(){
          // ERRO CORS
//        const response = await api.get("/api/usuarios/destroytoken",{headers:{token: getToken()}});
//        const response = await api.get("/api/usuarios/destroytoken");
//        if(response.status===200){
            dispatch(Actions.closeDialog());
            logout();
            window.location.href = `/login/${sourceArea}`;
//        }else{
//          alert("Erro ao fazer o logout!");
//        }
    }

  return(
  <Fragment>
      <div className={classes.root}>
          <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
              <Toolbar className={classes.toolbar}>
              <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                  <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                  {currentTitle}
              </Typography>

              <IconButton color="default" aria-label="theme" component="span" onClick={handleThemeChange} >
                {isDark? <WbSunnyOutlinedIcon /> : <Brightness2Icon /> }
              </IconButton>

              {getNomeUsuario()}
              </Toolbar>
          </AppBar>
          <Drawer variant="permanent" classes={{
                                      paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                                      }} open={open} >

              <div className={classes.toolbarIcon}>
                  <img style={{height:32}} src={isDark? logoSistemaDark:logoSistemaNormal} alt="Logo sistema" />
                  <IconButton onClick={handleDrawerClose}>
                      <ChevronLeftIcon />
                  </IconButton>
              </div>

              <Divider />
              { itens? <Sidebar items={itens} /> : null }
              <ListItem 
                  button 
                  onClick={
                  ()=> dispatch(Actions.openDialog({
                  children: (
                      <React.Fragment>
                          <DialogTitle id="alert-dialog-title">Deseja realmente sair do sistema?</DialogTitle>
                          <DialogActions>
                              <Button onClick={()=> dispatch(Actions.closeDialog())} color="primary">
                                  Cancelar
                              </Button>
                              <Button onClick={Sair} color="primary" autoFocus>
                                  Sim
                              </Button>
                          </DialogActions>
                      </React.Fragment>
                  )
                  }))
                  }>
                  <ListItemIcon className={classes.sidebarItem}>
                      <ExitToApp />
                  </ListItemIcon>
                  <ListItemText className={classes.sidebarItemText} primary="Sair" />
              </ListItem>
          </Drawer>
          <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="lg" className={classes.container}>
                <Outlet />
              </Container>
              <Box pt={1} pb={3}>
                  <Footer />
              </Box>
          </main>

      </div>
      <ShowDialog/>
      <ShowMessage/>
  </Fragment>
  );
    
};
  
export default MenuAdmin;
